import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Button, Card, Accordion, ListGroup } from "react-bootstrap"
import FontIcon from "../components/fonticon"
import CourseForm from "../components/CourseForm"

export const query = graphql`
  query($slug: String!) {
    coursePageDataset(SlugURL: { eq: $slug }) {
      id
      CourseContent {
        Modules
        Name
        Description
      }
      FAQ {
        A
        Q
        Number
      }
      CourseHighlights {
        icon
        title
      }
      CourseFeatures {
        param
        value
      }
      FeaturedYoutubeId
      Id
      LearningObjectives
      SlugURL
      Title
      BannerDescription
      LongDescription
      SEO {
        param
        value
      }
    }
    allCourseDataset(filter: { SlugURL: { eq: $slug } }) {
      edges {
        node {
          ThumbnailURL
        }
      }
    }
  }
`

const Course = ({ data }) => {
  const course = data.coursePageDataset
  const courseImg = data.allCourseDataset

  const [meta, setMeta] = useState({
    title: "",
    description: "",
    image: "",
    keywords: "",
    url: "",
    author: "",
  })

  useEffect(() => {
    {
      course.SEO &&
        course.SEO.map(item => {
          switch (item.param) {
            case "Title":
              setMeta(prevState => {
                return { ...prevState, title: item.value ? item.value : "" }
              })
              break

            case "Description":
              setMeta(prevState => {
                return {
                  ...prevState,
                  description: item.value ? item.value : "",
                }
              })
              break

            case "Url":
              setMeta(prevState => {
                return { ...prevState, url: item.value ? item.value : "" }
              })
              break

            case "Image":
              setMeta(prevState => {
                return { ...prevState, image: item.value ? item.value : "" }
              })
              break

            case "Keywords":
              setMeta(prevState => {
                return {
                  ...prevState,
                  keywords: item.value ? item.value : "",
                }
              })
              break

            case "Author":
              setMeta(prevState => {
                return { ...prevState, author: item.value ? item.value : "" }
              })
              break

            default:
            // Default code
          }
        })
    }
  }, [course.SlugURL])

  const [courseModalShow, setCourseModalShow] = useState(false)

  const handleCourseModal = () => {
    setCourseModalShow(!courseModalShow)
  }

  const queryString = global.window?.location.search
  const urlParams = new URLSearchParams(queryString)

  const [utmParams, setUtmParams] = useState({
    utmSource: urlParams.get("utm_source") || "",
    utmMedium: urlParams.get("utm_medium") || "",
    utmCampaign: urlParams.get("utm_campaign") || "",
  })

  const courseFeaturedImg = courseImg.edges.map(item => {
    if (item.node.ThumbnailURL) {
      return item.node.ThumbnailURL
    } else {
      return "../../images/courses/html.svg"
    }
  })

  return (
    <div style={{ backgroundColor: "#f9fafe" }}>
      <Layout title={meta.title}
        description={meta.description}
        image={meta.image}
        keywords={meta.keywords}
        url={meta.url}
        author={meta.author} >
        <div className="headerShadow"></div>
        <div class="container p-4">
          <h2>{course.Title}</h2>
          <div class="row">
            <div class="col-md-6 col-lg-6 col-xl-6">
              <div style={{ maxWidth: "500px" }}>
                <Card style={{ border: "none" }}>
                  <Card.Img
                    style={{ borderRadius: "10px" }}
                    variant="top"
                    src={courseFeaturedImg}
                  />
                  <Card.Body
                    className="pb-0 pl-0"
                    style={{ backgroundColor: "#f9fafe" }}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: course.BannerDescription,
                      }}
                    />
                  </Card.Body>
                </Card>
                <Button
                  className="outline-secondary"
                  style={{ marginBottom: "90px" }}
                  onClick={handleCourseModal}
                >
                  Enquire Now
                </Button>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-6 pl-4 pr-4">
              <Accordion>
                {course.CourseContent &&
                  course.CourseContent.map(modules => (
                    <Card className="cardShadow">
                      <Card.Header className="bg-white">
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey={modules.Name}
                          className="cramCourseAccordion text-decoration-none"
                        >
                          <h5>{modules.Name}</h5>
                          <hr />
                          {modules.Description}
                          <p>
                            <br></br>
                            <a href="javascript:void(0);" class="text-dark">
                              view more
                            </a>
                          </p>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={modules.Name}>
                        <Card.Body className="p-0">
                          <ListGroup>
                            {modules.Modules.map(item => (
                              <ListGroup.Item className="p-l-2 p-t-4 p-b-4 cram-course-list-group-item">
                                <FontIcon tag="faPlay" />
                                {item}
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}
              </Accordion>
            </div>
          </div>
        </div>
      </Layout>
      {courseModalShow && (
        <CourseForm
          utm={utmParams}
          course={course}
          setCourseModalShow={setCourseModalShow}
        />
      )}
    </div>
  )
}

export default Course
